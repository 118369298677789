import React, { Component } from "react";
import { get, isEmpty } from "lodash";
import { SafeTripleColors } from "../../../constants/colors";
import { GraphContainer, TimePeriod, StepSize } from "ui";
import styled from "styled-components";
import strings from "../strings";

class PeopleCountMonths extends Component {

  render() {
    // console.log("PeopleCountMonths props", this.props);

    // Do not render if there are no queries
    if (isEmpty(this.props.queries)) {
      return null;
    }

    // Get graph name
    const graphKey = "month";

    // Get localized strings
    strings.setLanguage(this.props.language || "en");

    // Add either workplace categories or custom tags as entity names
    let entities = {};
    if (get(this.props, "queries[0].summed", false)) {
      this.props.queries.forEach(query => {
        let selectedCustomTagId = query.customTagId;
  
        // Add custom tag
        if (selectedCustomTagId && selectedCustomTagId !== "all") {
          const customTag = this.props.customTags.find(tag => tag.id === selectedCustomTagId);
          if (customTag) {
            entities[selectedCustomTagId] = { name: customTag.name };
          }
        }
        else {
          // Add location
          entities[query.locationId] = { name: get(query, "location.name", "") };
        }
      });
    }
    else {
      // Add locations to entities, if samples are connected to specific locations
      this.props.queries.forEach(query => {
        const hashKey = `${graphKey}-${JSON.stringify(query.dateRanges)}-${query.includeHolidays}-${query.includeHolidays}-${query.includeWeekends}-${query.locationId}-${query.customTagId}-${query.summed}`;
        const samples = this.props.queryData[hashKey] || [];
        samples.forEach(sample => {
          if (sample.locationId) {
            entities[sample.locationId] = { name: sample.name }; //get(sample, "location.name", "")
          }
        });
      });
    }

    // Add colors to each entity
    Object.keys(entities).forEach((entityId, index) => {

      let keys = [];
      let colors = [];

      // Add color to each active data key
      this.props.graphDataKeys.forEach((keyObject, keyIndex) => {
        if (keyObject.show) {
          keys.push(keyObject.key);
          colors.push(SafeTripleColors[index % 11][keyIndex]);
        }
      });

      entities[entityId] = { ...entities[entityId], colors, keys };
    });

    // Get maximum value for all graphs
    let min = 0;
    let max = 10;
    const allPossibleKeys = [...this.props.graphDataKeys.filter(dk => dk.show).map(dk => dk.key), "capacity"];
    if (this.props.queries && this.props.queries.length > 0) {
      max = Math.max(...this.props.queries.map(query => {
        let hashKey = `${graphKey}-${JSON.stringify(query.dateRanges)}-${query.includeHolidays}-${query.includeHolidays}-${query.includeWeekends}-${query.locationId}-${query.customTagId}-${query.summed}`;
        hashKey += query.graphScaleType === "percentage" ? "-percentage" : "";
        const samples = this.props.queryData[hashKey] || [];

        // Sample data is an array of objects with keys: avgAvg, avgPeak, peakPeak, capacity
        if (samples.length > 0) {
          
          // Get all values in an array, filtering out undefined values
          const allValues = samples.flatMap(sample => 
            allPossibleKeys.map(key => sample[key]).filter(value => value !== undefined)
          );

          // Return the maximum value, ensuring we consider at least 0 if allValues is empty
          return allValues.length > 0 ? Math.max(...allValues, 0) : 0;
        }
  
        return query.graphScaleType === "percentage" ? 100 : 10;
      }));
    }
    // Add 10% to the maximum value and round up to closest 10 - to make the graphs easier to compare
    max = Math.ceil(max * 1.1 / 10) * 10;

    // Estimate a dynamic step size based on the magnitude of the maxValue
    const magnitude = Math.pow(10, Math.floor(Math.log10(max))); // Get the scale of the maxValue
    const dynamicStepSize = magnitude / 2; // Adjust as needed (e.g., /2 or /5 for finer steps)

    // Round the maxValue up to the nearest multiple of the dynamic step size
    max = Math.ceil(max / dynamicStepSize) * dynamicStepSize;

    return (
      <Row>
        {
          this.props.queries.map((query, index) => {
            let hashKey = `${graphKey}-${JSON.stringify(query.dateRanges)}-${query.includeHolidays}-${query.includeHolidays}-${query.includeWeekends}-${query.locationId}-${query.customTagId}-${query.summed}`;
            
            const loadingStatus = this.props.queryLoading[hashKey] ?? { type: "not started" };
            const isLoading = loadingStatus.type === "loading";

            hashKey += query.graphScaleType === "percentage" ? "-percentage" : "";
            const samples = this.props.queryData[hashKey] || [];

            // Add capacity to entities and keys
            let queryKeys = this.props.graphDataKeys;
            let queryEntities = { ...entities };
            for (let j=0; j < samples.length; j++) {
              if (get(samples[j], "entityId", undefined) === "capacity") {
                queryEntities["capacity"] = { name: "capacity", colors: ["#222"], keys: ["capacity"] };
                queryKeys = [...queryKeys, "capacity"];
                break;
              }
            }

            // console.log("hashKey", hashKey);
            // console.log("entities", entities);
            // console.log("allKeys", allKeys);
            // console.log("samples", samples);
            // console.log("min", min);
            // console.log("max", max);
            
            return (
              <Col key={hashKey + index} $isGraph $expanded={this.props.queries.length === 1}>
                <GraphContainer
                  source="report"
                  dataType="peopleCount"
                  locationName={get(query.location, "name", "?")}
                  samples={samples}
                  y={{ min: 0, max: max, zoomMin: min, zoomMax: max, unit: query.graphScaleType === "percentage" ? "%" : "" }}
                  x={{ min: get(query.dateRanges, "[0].startDate", null), max: get(query.dateRanges, `[${query.dateRanges.length-1}].endDate`, null), dateRanges: query.dateRanges }}
                  allKeys={queryKeys}
                  entities={queryEntities}
                  graphType={query.graphType}
                  graphScaleType={query.graphScaleType}
                  graphShowLabels={query.graphShowLabels}
                  includeWeekends={query.includeWeekends}
                  language={this.props.language}
                  isLoading={isLoading}
                  statusMessage={!isLoading && isEmpty(samples) ? strings.noData : undefined}
                  stepSize={StepSize.Month}
                  timePeriod={TimePeriod.Month}
                  onDraggingStart={() => this.setState({ isDraggingSlider: true })}
                  onDraggingEnd={() => this.setState({ isDraggingSlider: false })}
                  showPNGOption
                  showCSVOption
                  toggleGraphType={() => this.props.onGraphTypeChanged(index, query.graphType === "line" ? "bar" : "line")}
                  toggleGraphScaleType={() => this.props.onGraphScaleTypeChanged(index, query.graphScaleType === "count" ? "percentage" : "count")}
                  toggleGraphShowLabels={() => this.props.onGraphShowLabelsChanged(index, !query.graphShowLabels)}
                />
              </Col>
            );
          })
        }
      </Row>
    );
  }
}

export default PeopleCountMonths;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  justify-content: flex-start;
  padding-bottom: 40px;
`;

// Set a fixed width for the graphs
// This is to prevent the graphs from being too small on small screens
// and show the graphs side by side on large screens
const Col = styled.div`
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: ${(props) => props.$expanded ? "100%" : (props.$isGraph ? "50%" : "100%")};
  width: ${(props) => props.$expanded ? "100%" : (props.$isGraph ? "50%" : "100%")};
  min-width: ${(props) => props.$expanded ? "100%" : (props.$isGraph ? "508px" : "200px")};
  max-width: ${(props) => props.$expanded ? "100%" : (props.$isGraph ? "calc(50% - 5px)" : "100%")};

  @media (max-width: 1600px) {
    flex-basis: 100%;
    width: 100%;
    min-width: 200px;
    max-width: 100%;
  }
`;